<script>
  import HeroImage from '@/images/landing-page/scorpio/ttr/lp_hero.png'
  import GuaranteeStickerImage from '@/images/landing-page/scorpio/ttr/guarantee_sticker.png'
  import DeviceImage from '@/images/landing-page/scorpio/ttr/lp_device.png'

  import WhatLeftImage from '@/images/landing-page/scorpio/ttr/what-left.png'
  import WhatCenterImage from '@/images/landing-page/scorpio/ttr/what-center.png'
  import WhatRightImage from '@/images/landing-page/scorpio/ttr/what-right.png'
  import Section from '@/components/landing-page/widgets/Section.svelte'
  // import { Accordion } from '@/components'
  import Accordion from './components/Accordion.svelte'

  import Tracker from '@/util/tracker.js'

  import LogoImage from '@/images/landing-page/scorpio/ttr-logo.png'

  import BusinessInsiderImage from '@/images/landing-page/scorpio/business-insider.png'
  import ForbesImage from '@/images/landing-page/forbes-simple.png'
  import CarAndDriverImage from '@/images/landing-page/scorpio/car-and-driver-color.png'
  import MashableImage from '@/images/landing-page/scorpio/mashable-round.png'
  import FoxNewsImage from '@/images/landing-page/scorpio/fox-color.png'
  import FaqImage from '@/images/landing-page/scorpio/ttr/faq.png'
  import BottomImage from '@/images/landing-page/scorpio/ttr/bottom.png'
  import BottomMobileImage from '@/images/landing-page/scorpio/ttr/bottom-mobile.png'

  import {
    Checkmark,
    CloseRound,
    StarFilledSharp,
    Chevron,
    SteeringWheel,
    WheelMoving,
    GuardCheck,
    Coins,
    MagnifyingGlassList,
    CarsCrash,
    StarFilledRound,
  } from '@/components/icons'
  import ReviewCarousel from './components/ReviewCarousel.svelte'

  $: checkoutLink = 'https://shop.fixdapp.com/lp/sensor67-adv'

  const onClick = async (event) => {
    try {
      Tracker.track('trusted tech reviews link clicked', {
        page_name: 'TTRHotNewCarGadget',
        clean_url: `${window.location.origin.toString()}${window.location.pathname.toString()}`,
      })
    } catch (error) {
      throw new Error('trusted tech reviews link click error')
    } finally {
      window.location.href = event.target.href
    }
  }
</script>

<svelte:head>
  <link rel="preload" href={HeroImage} as="image" />
  <link rel="preload" href={GuaranteeStickerImage} as="image" />
  <link rel="preload" href={LogoImage} as="image" />
  <link rel="preload" href={BusinessInsiderImage} as="image" />
  <link rel="preload" href={ForbesImage} as="image" />
  <link rel="preload" href={MashableImage} as="image" />
  <link rel="preload" href={CarAndDriverImage} as="image" />
  <link rel="preload" href={FoxNewsImage} as="image" />
</svelte:head>

<main class="font-montserrat relative">
  <div class="my-2">
    <p class="text-center font-inter text-sm italic text-grey">Advertorial</p>
  </div>

  <div class="bg-[#3E4148] text-center py-2">
    <a
      href={checkoutLink}
      on:click|preventDefault={onClick}
      class="text-13 text-white font-inter font-medium no-underline"
      >The FIXD Sale is LIVE! - <span class="font-bold">
        GET UP TO 67% OFF TODAY!</span
      ></a
    >
  </div>

  <Section maxWidth="xl" verticalPadding="short">
    <div class="flex justify-between items-center">
      <div class="basis-1/2">
        <img src={LogoImage} alt="atr-logo" class="md:w-1/2" />
      </div>
      <div class="flex-1 w-full">
        <a
          href={checkoutLink}
          on:click|preventDefault={onClick}
          class="md:hidden flex no-underline items-center gap-2 justify-center bare py-2 w-full inline-block bg-green uppercase text-center font-semibold text-13 rounded-full text-white"
        >
          Get Up to 67% Offss
          <span class="inline-block -mt-0.5">
            <Chevron direction="right" color="white" size="xs" />
          </span>
        </a>
      </div>
    </div>
  </Section>

  <div class="space-y-16 md:space-y-[148px]">
    <div class="max-w-screen-2xl px-3 md:px-0 mx-auto">
      <div class="flex flex-col md:flex-row">
        <div class="basis-1/2">
          <div class="space-y-6 text-center md:text-left md:pl-[15%]">
            <div class="border rounded-full px-4 py-1 inline-flex items-center">
              <div class="flex items-center gap-1 mr-4">
                {#each new Array(5) as _}
                  <StarFilledRound color="yellow" size="sm" />
                {/each}
              </div>
              <p class="text-13 font-inter font-semibold">
                OVER 422,403 5-STAR REVIEWS
              </p>
            </div>

            <h1 class="text-[35px] md:text-[60px] font-bold">
              The Hottest <br />
              <span class="text-green">New Car Gadget</span> <br />
              of 2024
            </h1>
            <p class="text-15 md:text-xl font-bold">
              “It can save you thousands on car repairs.”
            </p>

            <a
              href={checkoutLink}
              on:click|preventDefault={onClick}
              class="bare py-2.5 inline-flex gap-2 items-center justify-center inline-block px-6 bg-green no-underline uppercase text-center font-semibold text-17 rounded-full text-white"
            >
              Get Up to 67% Off
              <span class="inline-block -mt-0.5">
                <Chevron direction="right" color="white" size="xs" />
              </span>
            </a>
            <div class="max-w-[505px] hidden md:block">
              <div class="flex items-center gap-3 mb-6 pt-5">
                <p class="shrink-0 font-semibold text-13 font-inter">
                  AS SEEN ON
                </p>
                <div class="h-[1px] w-full bg-[#D9D9D9]" />
              </div>

              <div class="flex w-full gap-6 items-center">
                <img
                  src={BusinessInsiderImage}
                  alt="Business Insider"
                  class="h-[30px]"
                />
                <img
                  src={CarAndDriverImage}
                  alt="Car and Driver"
                  class="h-[18px]"
                />
                <img src={MashableImage} alt="Mashable" class="h-[14px]" />
                <img src={ForbesImage} alt="Forbes" class="h-6" />
                <img src={FoxNewsImage} alt="Fox News" class="h-12" />
              </div>
            </div>
          </div>
        </div>
        <div class="basis-1/2 mt-8 md:mt-0">
          <div class="relative">
            <img
              src={HeroImage}
              alt="100% guarantee back"
              class="rounded-t-[60px] md:rounded-l-[60px] md:rounded-r-none"
            />
            <div class="absolute top-5 md:top-7 left-4 md:left-7">
              <img
                src={GuaranteeStickerImage}
                alt="money back guarantee"
                class=" w-[110px] md:w-[142px]"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="md:hidden">
        <div class="flex items-center gap-3 mb-6 pt-5">
          <div class="h-[1px] w-full bg-[#D9D9D9]" />
          <p class="shrink-0 font-semibold text-13 font-inter">AS SEEN ON</p>
          <div class="h-[1px] w-full bg-[#D9D9D9]" />
        </div>

        <div class="flex w-full gap-6 items-center flex-wrap justify-center">
          <img src={BusinessInsiderImage} alt="Business Insider" class="h-8" />
          <img src={CarAndDriverImage} alt="Car and Driver" class="h-[18px]" />
          <img src={MashableImage} alt="Mashable" class="h-[18px]" />
          <img src={ForbesImage} alt="Forbes" class="h-8" />
          <img src={FoxNewsImage} alt="Fox News" class="h-15" />
        </div>
      </div>
    </div>

    <Section maxWidth="xl" center={true} verticalPadding="none">
      <div class="md:w-10/12 mx-auto mb-9">
        <h1 class="text-30 md:text-[60px]">
          Stop Paying Thousands of Dollars on <span class="text-green"
            >Your Car Repair Bills</span
          >
        </h1>
      </div>
      <div class="md:w-8/12 mx-auto space-y-6 text-15 md:text-17">
        <p class="text-15 md:text-xl font-bold md:mb-16">
          “Imagine owning a device that could practically replace your
          mechanic.”
        </p>

        <p>
          I'm back from the car repair shop, and <span class="font-bold"
            >I'm so mad, I could spit nails!</span
          >
        </p>

        <p class="text-lg md:text-25 font-bold md:pb-5">
          “They Tried to Charge Me $2,300…”
        </p>

        <p>
          All I wanted was for a mechanic to diagnose my check engine light…
        </p>

        <p>
          But can you believe <span class="font-bold"
            >they tried to charge me $2,300</span
          >
          to fix the car…
          <span class="font-bold"
            >including a $300 diagnostic fee?! I drove away feeling absolutely
            livid</span
          >
          (and without paying him a penny)!
        </p>

        <p>
          But even though I dodged a bullet, that experience woke me up to a
          shocking fact:
        </p>

        <p class="text-lg md:text-25 font-bold md:py-4">
          Car Repair Costs Are Skyrocketing!
        </p>

        <p>
          If you're like me, <span class="font-bold"
            >paying a car repair bill</span
          >
          probably feels like you're
          <span class="font-bold">getting ripped off.</span> And with costs on the
          rise, it's only going to get more painful - the typical repair bill now
          hovers around $550!
        </p>

        <p class="text-lg font-bold md:pt-4">
          I got sick of paying high car repair bills too.
        </p>
      </div>
    </Section>

    <div class="max-w-screen-2xl px-3 md:px-0 mx-auto">
      <div class="md:w-10/12 mx-auto mb-9 md:mb-24 text-center">
        <h1 class="text-30 md:text-[60px]">
          The Car Tech Gadget That <br class="hidden md:block" /><span
            class="text-green">Saved Me $2,200</span
          >
          With One Use.
        </h1>
      </div>
      <div class="flex flex-col-reverse md:flex-row items-center">
        <div class="basis-1/2">
          <div
            class="space-y-9 text-center md:text-left md:pl-[15%] text-15 md:text-17 md:w-11/12"
          >
            <p class="text-xl md:text-25 font-bold">
              The device came with a <span class="text-green"
                >30-day, <br class="hidden md:block" />
                100% money-back guarantee.</span
              >
            </p>
            <p>
              So I knew I wasn't going to risk anything by trying it out. I
              decided to pull the trigger and see if it could really help me fix
              my car.
            </p>

            <p>
              Within just a few days of ordering, my device arrived. I completed
              the simple setup process, scanned my car, and <span
                class="font-bold">found out what the problem was</span
              >
              - all in about <span class="font-bold">one minute.</span>
            </p>

            <p class="font-bold">
              I can't believe one little device just saved me $2,200!
            </p>
          </div>
        </div>
        <div class="basis-1/2 md:mt-0 mb-10 md:mb-0">
          <img
            src={DeviceImage}
            alt="100% guarantee back"
            class="rounded-t-[60px] md:rounded-l-[60px] md:rounded-r-none"
            loading="lazy"
          />
        </div>
      </div>
    </div>

    <Section center={true} maxWidth="2xl" verticalPadding="none">
      <h1 class="text-30 md:text-[60px] mb-10">What is It?</h1>
      <div class="md:w-9/12 mx-auto">
        <p class="font-bold text-lg md:text-xl md:w-4/5 mx-auto">
          It's called <a
            href={checkoutLink}
            on:click|preventDefault={onClick}
            class="text-black-1">FIXD</a
          >, and it's helping millions of everyday drivers just like you save
          $1,000s in car repair costs.
        </p>
      </div>

      <div
        class="flex items-center flex-wrap md:flex-nowrap gap-5 md:gap-10 mt-9 md:mt-20"
      >
        <div class="flex-1 h-full basis-full md:basis-[44%] md:order-2">
          <img
            src={WhatCenterImage}
            class="rounded-[35px] md:rounded-[60px] w-full"
            alt="FIXD Device"
            loading="lazy"
          />
        </div>
        <div class="flex-1 md:basis-[28%] md:order-1 w-1/2">
          <img
            src={WhatLeftImage}
            class="rounded-[35px] md:rounded-r-[60px] md:rounded-l-none w-full"
            alt="driver"
            loading="lazy"
          />
        </div>
        <div class="flex-1 md:basis-[28%] md:order-3 w-1/2">
          <img
            src={WhatRightImage}
            class="rounded-[35px] md:rounded-l-[60px] md:rounded-r-none w-full"
            alt="plug in FIXD"
            loading="lazy"
          />
        </div>
      </div>

      <div
        class="space-y-6 text-15 md:text-17 max-w-screen-md mx-auto mt-10 md:mt-16"
      >
        <p>
          <a href={checkoutLink} on:click|preventDefault={onClick}>FIXD</a> is a
          pocket-sized sensor that plugs right under your car's steering wheel.
        </p>
        <p>
          Then it connects to your smartphone to tell you what's wrong with your
          car, help you save on maintenance and repairs, and even predict future
          problems your car is likely to have.
        </p>
      </div>
    </Section>

    <Section verticalPadding="none" maxWidth="xl">
      <div class="text-center mb-10 md:mb-20">
        <h1 class="text-30 md:text-[60px] mb-8">
          The <span class="text-green">FIXD</span> Difference!
        </h1>
        <div class="md:w-9/12 mx-auto">
          <p class="font-bold text-lg md:text-xl md:w-4/5 mx-auto">
            It works just like your mechanic's bulky, expensive pro scanner, <br
              class="hidden md:block"
            />
            <span class="text-green">but gives you much more:</span>
          </p>
        </div>
      </div>

      <article class="space-y-4 text-sm leading-5">
        <div class="flex flex-col lg:flex-row font-medium">
          <div class="flex-1">
            <header
              class="flex items-center justify-center py-4 w-9/12 md:w-1/2 mx-auto bg-green rounded-t-3xl"
            >
              <h4 class="text-white text-17">FIXD PERK</h4>
            </header>
            <div
              class="px-4 py-5 md:p-13 space-y-6 border rounded-2xl lg:rounded-s-[3rem] lg:rounded-e-none lg:border-r-0"
            >
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Works on all gas cars made after 1995
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Clear your check engine light (with 1 tap)
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Accurately diagnoses 7,000+ car problems
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Explains car problems in simple terms
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Gives you an accurate repair cost estimate using AI, so you
                  never overpay
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Gives you access to expert live mechanics to help with repairs
                  and questions right now.
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Maintenance reminders to help you avoid unneeded repairs
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-green rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Predict future issues before they happen
                </p>
              </div>

              <div class="text-center">
                <a
                  href={checkoutLink}
                  on:click|preventDefault={onClick}
                  class="bg-green px-6 py-3 text-white gap-2 inline-flex items-center uppercase rounded-full no-underline font-bold text-17 inline-block"
                >
                  get up to 67% off fixd
                  <span class="inline-block -mt-1">
                    <Chevron direction="right" color="white" size="xs" />
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div class="flex-1 pt-11 sm:pt-0">
            <header
              class="flex items-center justify-center py-4 w-9/12 md:w-1/2 mx-auto bg-black rounded-t-3xl"
            >
              <h4 class="text-white text-center text-17">
                Mechanic's Pro Scanner
              </h4>
            </header>
            <div
              class="px-4 py-5 md:p-13 space-y-6 border rounded-2xl lg:rounded-e-[3rem] lg:rounded-l-none"
            >
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-black rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Works on all gas cars made after 1995
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-black rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Clear your check engine light
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-black rounded flex items-center justify-center"
                >
                  <Checkmark color="white" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Accurately diagnoses over 7,000 common car problems
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-red shrink-0 rounded flex items-center justify-center"
                >
                  <CloseRound color="white" size="sm" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Have to interpret confusing error codes
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-red shrink-0 rounded flex items-center justify-center"
                >
                  <CloseRound color="white" size="sm" />
                </div>
                <p class="text-15 md:text-[17px]">
                  Mechanics will still charge you up to $300 to diagnose your
                  check engine light
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-red shrink-0 rounded flex items-center justify-center"
                >
                  <CloseRound color="white" size="sm" />
                </div>
                <p class="text-15 md:text-[17px]">
                  You have to go to the car repair shop, where your mechanic
                  could try to rip you off
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-red shrink-0 rounded flex items-center justify-center"
                >
                  <CloseRound color="white" size="sm" />
                </div>
                <p class="text-15 md:text-[17px]">
                  No support for maintenance reminders
                </p>
              </div>
              <div class="flex gap-4 items-center">
                <div
                  class="size-6 bg-red shrink-0 rounded flex items-center justify-center"
                >
                  <CloseRound color="white" size="sm" />
                </div>
                <p class="text-15 md:text-[17px]">Only shows current issues</p>
              </div>

              <div class="text-center">
                <p
                  class="bg-red px-6 py-3 text-white uppercase rounded-full no-underline font-bold text-17 inline-block"
                >
                  COST: OVER $2,000
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Section>

    <Section
      additionalClass="max-w-[1075px] mx-auto"
      maxWidth="2xl"
      verticalPadding="none"
    >
      <div class="text-center mb-10 md:mb-20">
        <h1 class="text-30 md:text-[60px] mb-8">
          How Does
          <span class="text-green">FIXD</span> Work?
        </h1>
        <div class="md:w-9/12 mx-auto">
          <p class="font-bold text-lg md:text-xl mx-auto">
            FIXD is easy to use! There are just three simple steps to get
            started:
          </p>
        </div>
      </div>
      <div class="md:flex flex-col md:flex-row items-center gap-10">
        <div class="basis-1/2">
          <div class="space-y-6 text-15 md:text-17">
            <div class="border rounded-3xl py-3 px-4">
              <div class="flex items-center gap-4 md:gap-6">
                <div
                  class="bg-green rounded-xl px-3 py-1.5 text-white shrink-0"
                >
                  <p class="font-bold">STEP 1</p>
                </div>
                <p class="font-bold">Plug your FIXD scanner into your car.</p>
              </div>
            </div>
            <div class="border rounded-3xl py-3 px-4">
              <div class="flex items-center gap-4 md:gap-6">
                <div
                  class="bg-green rounded-xl px-3 py-1.5 text-white shrink-0"
                >
                  <p class="font-bold">STEP 2</p>
                </div>
                <p class="font-bold">
                  Open the FIXD app on your Android or Apple smartphone.
                </p>
              </div>
            </div>
            <div class="border rounded-3xl py-3 px-4">
              <div class="flex items-center gap-4 md:gap-6">
                <div
                  class="bg-green rounded-xl px-3 py-1.5 text-white shrink-0"
                >
                  <p class="font-bold">STEP 3</p>
                </div>
                <p class="font-bold">Click the green “Tap to Scan” button.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="basis-1/2 my-8 md:my-0">
          <div class="relative pb-[56.25%]">
            <iframe
              class="absolute top-0 left-0 w-full h-full"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/C2I-HDuSrKU?si=ftd6qjlUvpvwpQTq"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowfullscreen
            />
          </div>
        </div>
      </div>
      <div
        class="text-15 md:text-17 md:w-10/12 mx-auto text-center space-y-6 md:my-16"
      >
        <p>
          But FIXD isn't just a code reader - it provides resources to help you
          to save money by doing repairs yourself instead of letting a mechanic
          rip you off.
        </p>

        <p>
          For example, you can always <span class="font-bold"
            >call the <a
              href={checkoutLink}
              on:click|preventDefault={onClick}
              class="text-black-1">FIXD Mechanic Hotline</a
            ></span
          > during business hours. An expert mechanic will walk you through repairs,
          review shop estimates, answer questions about your car, and much more.
        </p>
      </div>

      <div class="mt-10 text-center">
        <p>
          You also get plenty of other tools <span class="font-bold"
            >to help you save money and time, including:</span
          >
        </p>
      </div>

      <div class="mt-16">
        <div class="grid md:grid-cols-2 gap-5">
          <div
            class="md:basis-1/2 border rounded-3xl p-6 md:p-8 md:min-h-[230px]"
          >
            <div class="flex items-center gap-6">
              <div class="shrink-0">
                <WheelMoving color="green" class="size-12 md:size-14" />
              </div>
              <p class="font-bold text-17 md:text-xl">
                Get Car Repairs Help 24/7 From Experts
              </p>
            </div>
            <p class="text-15 md:text-17 mt-6">
              <a
                href={checkoutLink}
                on:click|preventDefault={onClick}
                class="text-black-1">FIXD's</a
              > revolutionary new AI mechanic gives you access to expert help, so
              you can confidently repair your vehicle, even outside of normal business
              hours.
            </p>
          </div>
          <div class="md:basis-1/2 border rounded-3xl p-6 md:p-8">
            <div class="flex items-center gap-6">
              <div class="shrink-0">
                <GuardCheck color="green" class="size-12 md:size-14" />
              </div>
              <p class="font-bold text-17 md:text-xl">
                No More Check Engine Light Anxiety
              </p>
            </div>
            <p class="text-15 md:text-17 mt-6">
              Instantly diagnose check engine lights, so you don't have to worry
              about what that warning light means.
            </p>
          </div>
          <div class="md:basis-1/2 border rounded-3xl p-6 md:p-8">
            <div class="flex items-center gap-6">
              <div class="shrink-0">
                <SteeringWheel color="green" class="size-12 md:size-14" />
              </div>
              <p class="font-bold text-17 md:text-xl">
                Know if it's Safe to Drive
              </p>
            </div>
            <p class="text-15 md:text-17 mt-6">
              You'll find out how severe your car problem is, so you know
              whether it's safe to drive… or if you need to take care of the
              issue immediately.
            </p>
          </div>
          <div class="md:basis-1/2 border rounded-3xl p-6 md:p-8">
            <div class="flex items-center gap-6">
              <div class="shrink-0">
                <Coins color="green" class="size-12 md:size-14" />
              </div>
              <p class="font-bold text-17 md:text-xl">
                Know What You Should Pay for Car Repairs
              </p>
            </div>
            <p class="text-15 md:text-17 mt-6">
              FIXD gives you an estimated cost for your repairs, so you'll know
              what you should pay before you take your car to the shop.
            </p>
          </div>
          <div class="md:basis-1/2 border rounded-3xl p-6 md:p-8">
            <div class="flex items-center gap-6">
              <div class="shrink-0">
                <MagnifyingGlassList color="green" class="size-12 md:size-14" />
              </div>
              <p class="font-bold text-17 md:text-xl">Predict Future Issues</p>
            </div>
            <p class="text-15 md:text-17 mt-6">
              See which problems are likely in your car's future, and how much
              they'll cost to repair.
            </p>
          </div>
          <div class="md:basis-1/2 border rounded-3xl p-6 md:p-8">
            <div class="flex items-center gap-6">
              <div class="shrink-0">
                <CarsCrash color="green" class="size-12 md:size-14" />
              </div>
              <p class="font-bold text-17 md:text-xl">
                Spot a Lemon - Instantly
              </p>
            </div>
            <p class="text-15 md:text-17 mt-6">
              Thinking of buying a used car? FIXD gives you a complete report on
              any used car, so you know whether it's a great find… or a lemon.
            </p>
          </div>
        </div>
      </div>
    </Section>

    <Section additionalClass="max-w-[1075px] mx-auto" verticalPadding="none">
      <div class="text-center mb-14 md:mb-20">
        <h1 class="text-30 md:text-[60px] mb-8">
          Why is FIXD the <span class="text-green">Top Car Tech Device</span> of
          2024?
        </h1>
        <div class="md:w-9/12 mx-auto">
          <p class="font-bold text-lg md:text-xl md:w-4/5 mx-auto">
            <a
              href={checkoutLink}
              on:click|preventDefault={onClick}
              class="text-black-1">FIXD</a
            > is getting very popular, and for good reason.
          </p>
        </div>
      </div>

      <div class="md:w-11/12 mx-auto space-y-6 md:font-medium">
        <div class="flex gap-4 items-center rounded-3xl border py-5 px-6">
          <div class="size-6 bg-green rounded flex items-center justify-center">
            <Checkmark color="white" />
          </div>
          <p class="text-15 md:text-[17px]">
            Costing less than $30, it's being called, <span class="font-bold">
              "this year's hottest car tech gadget"</span
            > on many of the big name sites.
          </p>
        </div>
        <div class="flex gap-4 items-center rounded-3xl border py-5 px-6">
          <div class="size-6 bg-green rounded flex items-center justify-center">
            <Checkmark color="white" />
          </div>
          <p class="text-15 md:text-[17px]">
            Since being picked up by all the big news outlets, <span
              class="font-bold">FIXD has sold over 3 million+ sensors</span
            >
            across the U.S. and Canada,
            <span class="font-bold">with 422,403 5-star ratings</span>
          </p>
        </div>
        <div class="flex gap-4 items-center rounded-3xl border py-5 px-6">
          <div class="size-6 bg-green rounded flex items-center justify-center">
            <Checkmark color="white" />
          </div>
          <p class="text-15 md:text-[17px]">
            By the end of 2023 <a
              href={checkoutLink}
              on:click|preventDefault={onClick}
              class="text-black-1">FIXD</a
            >
            is expected
            <span class="font-bold">
              to become America's #1 best-selling car scanner.</span
            > Even professional mechanics are ditching their professional scan tools
            to use it too!
          </p>
        </div>
        <div class="flex gap-4 items-center rounded-3xl border py-5 px-6">
          <div class="size-6 bg-green rounded flex items-center justify-center">
            <Checkmark color="white" />
          </div>
          <p class="text-15 md:text-[17px]">
            <span class="font-bold">FIXD is 100% free to use forever</span> once
            you have the device.
            <span class="font-bold"
              >And over 100k people have opted-in to the optional <a
                href={checkoutLink}
                on:click|preventDefault={onClick}
                class="text-black-1">FIXD Premium</a
              > membership</span
            > for around $8/month, which gives you access to powerful features.
          </p>
        </div>
        <div class="flex gap-4 items-center rounded-3xl border py-5 px-6">
          <div class="size-6 bg-green rounded flex items-center justify-center">
            <Checkmark color="white" />
          </div>
          <p class="text-15 md:text-[17px]">
            If you sign up through <a
              href={checkoutLink}
              on:click|preventDefault={onClick}
              class="text-black-1">their discount page</a
            >, you'll get access to their no-commitment, free-trial so you can
            experience all the extra features at no additional cost to try it.
          </p>
        </div>
        <div class="flex gap-4 items-center rounded-3xl border py-5 px-6">
          <div class="size-6 bg-green rounded flex items-center justify-center">
            <Checkmark color="white" />
          </div>
          <p class="text-15 md:text-[17px]">
            <a
              href={checkoutLink}
              on:click|preventDefault={onClick}
              class="text-black-1">FIXD</a
            >
            even comes with a
            <span class="font-bold">FREE 1-year warranty</span>
            and a
            <span class="font-bold">30-day money-back guarantee</span>, so
            there's absolutely no risk to you. You can always return it.
          </p>
        </div>
      </div>
    </Section>

    <div class="max-w-screen-2xl px-3 md:px-0 mx-auto">
      <div class="space-y-6 text-center">
        <div class="border rounded-full px-4 py-2 inline-flex items-center">
          <div class="flex items-center gap-1 mr-4">
            {#each new Array(5) as _}
              <StarFilledSharp color="yellow" size="sm" />
            {/each}
          </div>
          <p class="text-13 font-inter font-semibold">
            OVER 422,403 5-STAR REVIEWS
          </p>
        </div>
        <div class="mt-10">
          <h1 class="text-[35px] md:text-[60px] font-bold">
            Real Reviews from <br />
            <span class="text-green">Real Customers</span>
          </h1>
        </div>
      </div>
      <div class="mt-16 md:mt-24">
        <ReviewCarousel />
      </div>
    </div>
    <Section verticalPadding="none">
      <div class="flex items-center gap-3 mb-6 pt-5">
        <div class="h-[1px] w-full bg-[#D9D9D9]" />
        <p class="shrink-0 font-semibold text-13 font-inter">AS SEEN ON</p>
        <div class="h-[1px] w-full bg-[#D9D9D9]" />
      </div>

      <div
        class="flex w-full gap-6 items-center flex-wrap justify-center md:justify-between"
      >
        <img
          src={BusinessInsiderImage}
          alt="Business Insider"
          class="h-8 md:h-12"
        />
        <img
          src={CarAndDriverImage}
          alt="Car and Driver"
          class="h-[18px] md::h-7"
        />
        <img src={MashableImage} alt="Mashable" class="h-[18px] md:h-[22px]" />
        <img src={ForbesImage} alt="Forbes" class="h-8 md:h-10" />
        <img src={FoxNewsImage} alt="Fox News" class="h-15 md:h-[76px]" />
      </div>
    </Section>

    <div class="max-w-screen-2xl px-3 md:px-0 mx-auto">
      <div class="w-10/12 mx-auto mb-9 md:mb-24 text-center">
        <h1 class="text-30 md:text-[60px]">Frequently Asked Questions</h1>
      </div>
      <div class="flex flex-col-reverse md:flex-row items-center">
        <div class="basis-1/2">
          <div
            class="space-y-6 text-center md:text-left md:pl-[15%] text-15 md:text-17 md:w-11/12 text-left"
          >
            <Accordion>
              <div slot="header">
                <p class="font-bold">Does FIXD work on my car?</p>
              </div>
              <div slot="body">
                <p>
                  FIXD works with all gas-powered cars and trucks built after
                  1996 and diesels built after 2008. The FIXD Sensor and app can
                  read all standardized OBD2 codes on most gas-powered vehicles
                  built in 1996 or newer. Enhanced codes such as ABS, airbag,
                  transmission, & TPMS are NOT supported.
                </p>
              </div>
            </Accordion>
            <Accordion>
              <div slot="header">
                <p class="font-bold text-left">
                  Can it really tell me what's wrong with my car?
                </p>
              </div>
              <div slot="body">
                <p class="text-left">
                  Yes. <a
                    href={checkoutLink}
                    on:click|preventDefault={onClick}
                    class="text-black-1">FIXD</a
                  >
                  gives you explanations of more than 7,000 check engine light codes,
                  in simple terms everyone can understand.
                </p>
              </div>
            </Accordion>
            <Accordion>
              <div slot="header">
                <p class="font-bold text-left">
                  Is it available on Apple and Android phones?
                </p>
              </div>
              <div slot="body">
                <p>
                  YES! The free FIXD app is compatible with both iPhones and
                  Android smartphones. It only takes a few minutes to set up and
                  start monitoring your car's health around the clock,
                  preventing $1000s in costly repairs and giving you more
                  confidence and control at the repair shop.
                </p>
              </div>
            </Accordion>
            <Accordion>
              <div slot="header">
                <p class="font-bold text-left">
                  Is there any advantage to ordering now?
                </p>
              </div>
              <div slot="body">
                <p>
                  Yes!
                  <a
                    href={checkoutLink}
                    on:click|preventDefault={onClick}
                    class="text-black-1">FIXD</a
                  >
                  is offering a deep discount right now to make it affordable to
                  get FIXD for yourself and your loved ones. Right now, you can get
                  FIXD at
                  <span class="text-red font-bold strikethrough">47%</span>
                  <span class="text-green font-bold">67%</span>
                  <span class="font-bold">off</span>. This sale won't last much
                  longer, though.
                </p>
              </div>
            </Accordion>
          </div>
        </div>
        <div class="basis-1/2 md:mt-0 mb-10 md:mb-0 text-right">
          <img
            src={FaqImage}
            alt="100% guarantee back"
            class="rounded-t-[60px] md:rounded-l-[60px] md:rounded-r-none"
            loading="lazy"
          />
        </div>
      </div>
    </div>
    <Section maxWidth="xl" center={true}>
      <div class="relative md:flex items-center">
        <div class="md:absolute z-10 w-full -mb-5">
          <div
            class="space-y-6 text-center md:text-left md:pl-[5%] bg-[#3E4148] md:bg-transparent rounded-t-[35px] pt-9 px-4 md:pt-0 md:pr-0"
          >
            <div
              class="border rounded-full px-3 py-2 inline-flex items-center bg-white"
            >
              <div class="flex items-center gap-0.5 mr-2">
                {#each new Array(5) as _}
                  <StarFilledRound color="yellow" size="sm" />
                {/each}
              </div>
              <p class="text-13 font-inter font-semibold">
                OVER 422,403 5-STAR REVIEWS
              </p>
            </div>

            <h1 class="text-[35px] md:text-[60px] font-bold text-white">
              FIXD Makes <br />Car Care Easy <br />
              <span class="text-green">for Any Driver</span>
            </h1>
            <p class="text-15 md:text-xl font-bold text-white">
              Save Money. Save Time. Avoid Stress
            </p>

            <div class="relative z-20">
              <a
                href={checkoutLink}
                on:click|preventDefault={onClick}
                class="bare py-2.5 inline-flex gap-2 items-center justify-center inline-block px-6 bg-green no-underline uppercase text-center font-semibold text-17 rounded-full text-white"
              >
                Get Up to 67% Off
                <span class="inline-block">
                  <Chevron direction="right" color="white" size="xs" />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="relative z-0">
          <img
            src={BottomImage}
            alt="Save Money Save Time"
            loading="lazy"
            class="hidden md:block rounded-[60px]"
          />
          <img
            src={BottomMobileImage}
            alt="Save Money Save Time"
            loading="lazy"
            class="md:hidden rounded-b-[35px]"
          />
        </div>
      </div>
    </Section>
  </div>
</main>
